@import ".~@nordcloud/insight-gui-theme/sass/index.scss";
@import "./custom/loading.scss";
@import "./custom//reveal.scss";

@import "~bulma/sass/components/modal";
@import "~bulma/sass/components/pagination";
@import "~bulma/sass/components/tabs";

@import "./vendors/_bootstrap.css";
body {
  margin: 0;
  padding: 0;
  background-color: #fafbfc;
}

a {
  &:hover {
    text-decoration: inherit !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.sidebar {
  background: linear-gradient(
    to bottom,
    #161632 60%,
    #ff2837 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  &:before {
    background: linear-gradient(
      to bottom,
      #161632 60%,
      #ff2837 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
}

.settings {
  .tile {
    //min-height: 20vh;
    border-radius: 1rem;
    margin-bottom: 1.5rem;

    .is-child {
      border: #161632 3px solid;
      cursor: pointer;
    }

    a {
      display: flex;
    }
  }
}
.sidebar.no-hover {
  width: 4.5rem !important;
}

.customer-menu {
  font-size: 2rem;
  li {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  margin-bottom: 2rem;
}

.customer-name {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.is-nav-title + nav {
  margin-left: 5px;
}
.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    color: $blue;
    &:after {
      @include loader;
      position: absolute;
      top: calc(50% - 0.8em);
      left: calc(50% - 0.8em);
      width: 1.6em;
      height: 1.6em;
      border-width: 0.25em;
    }
  }
}

.loader {
  width: 3.125rem;
  height: 3.125rem;
}

.is-filter {
  cursor: pointer;
}
.is-active-filter {
  font-weight: bold !important;
  cursor: pointer;
  position: relative;
  padding-right: 30px !important;
}

.is-filter-icon {
  color: #ff2837;
  font-size: 20px;
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(calc(-50% - 4px));
}

.is-delete {
  cursor: pointer;
}

.is-fitler-name {
  margin-right: 0.5rem;
}

.is-provider-logo {
  width: 30px;
  height: auto;
}
.page-header-title {
  strong {
    font-weight: normal;
  }
}

.box {
  &.is-hoverable {
    cursor: pointer;
    position: relative;
    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.05);
    }
  }
}

.select {
  max-width: 15rem;
}

.is-search-icon {
  transform: translate(6px, 6px);
}

.sdm {
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.nav {
  li {
    width: 100%;
  }
}

.has-besel {
  margin-bottom: 1.5rem;
}
.level {
  .has-besel {
    margin-bottom: 0px;
  }
}
.table {
  .is-iconly {
    padding: 8px 11px 8px 10px;
    height: 32px;

    i {
      font-size: 1rem;
      margin: 0 !important;
    }
  }
}

.form-title {
  margin-bottom: 1.5rem;
}

.message {
  background-color: inherit;
}

.editable {
  position: relative;
  cursor: pointer;
  &:hover {
    &:after {
      display: block;
      content: "\0270F";
      position: absolute;
      top: 0px;
      left: -15px;
    }
  }
}

.has-bg {
  padding-left: 8rem;
  position: relative;
  .settings-icon {
    width: 4rem;
    height: auto;
    left: 2rem;
    top: 50%;

    transform: translateY(-50%);
    position: absolute;
  }
}

.onboarding-message {
  display: block;
  background: #fff;
  padding: 1rem;
  border: 1px dashed #161632;
  color: #161632;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  min-height: 6rem;
  a {
    font-weight: bold;
  }
  img {
    float: left;
    width: 3rem;
    margin-right: 1rem;
  }
}

.select select {
  border-color: #eee;
}
.input.has-border {
  border-color: #eee;
}
.button:first-child {
  margin-left: 0px;
}
h1.title.has-background-light {
  font-size: 1rem;
  padding: 1rem;
}

.page-header-user i {
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

.is-icononly i {
  margin-right: 0 !important;
}

td.break {
  word-break: break-all;
}
td {
  white-space: pre-wrap; /* css-3 */

  word-wrap: break-word;
}

.longValue {
  display: block;
  max-width: 200px;
  height: 1rem;
  overflow: hidden;
}

.tags:not(:last-child) {
  margin-bottom: 0.4rem;
}
.tags {
  flex-wrap: nowrap;
}

i.is-light {
  opacity: 0.5;
}

header.page-header {
  z-index: 10;
}

.mapping-icon {
  margin-left: 0.3rem;
  transform: translateY(2px);
  cursor: pointer;
}

.tags.has-addons {
  .tag:first-of-type {
    width: 6.5rem;
  }
}

.bundle-container {
  margin-right: 1rem;
}
.is-hoverable {
  .remove-item-icon {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    display: none;
    opacity: 0.1;
  }
  &:hover {
    .remove-item-icon {
      display: block;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.has-table {
  margin: 20px 0px 10px 0px;
}

.modal button.button {
  margin-left: 0px !important;
}

.basic-single {
  width: 400px;
}

.modal-card {
  max-width: 1024px;
  min-width: 640px;
  width: auto;
}
